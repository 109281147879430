<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
      <b-overlay :show="unitLoad">
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="country_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.country_name')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.country_id"
                  :options="tradeTariffCountryList"
                  id="country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12" v-if="form.capital_id > 0">
            <ValidationProvider name="Capital" vid="capital_id">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="capital_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('tradeTariffConfig.capital_name')}}<span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  disabled
                  v-model="form.capital_id"
                  :options="capitalInfoList"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Area Measurement (sq km)" vid="area" rules="required|double|min_value:0">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="area"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('globalTrans.area')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.area"
                  id="Area"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Population" vid="population" rules="required|double|min_value:0">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="population"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('globalTrans.population')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="number"
                  v-model="form.population"
                  id="population"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Sea Ports" vid="sea_ports">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="sea_ports"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffApp.sea_port')}}
                </template>
                <v-select
                  id="sea_ports"
                  v-model="form.sea_ports"
                  multiple
                  :reduce="op => op.value"
                  :options="seaPortsList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Air Ports" vid="air_ports">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="air_ports"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('tradeTariffApp.air_port')}}
                </template>
                <v-select
                  id="air_ports"
                  v-model="form.air_ports"
                  multiple
                  :reduce="op => op.value"
                  :options="airPortsList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Land Ports" vid="land_ports">
              <b-form-group
                label-for="land_ports"
                label-cols-sm="4"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.land_port') }}
              </template>
              <v-select
                  id="land_ports"
                  v-model="form.land_ports"
                  multiple
                  :reduce="op => op.value"
                  :options="landPortsList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                {{ unitLoading ? 'Loading..' : $t('globalTrans.select')}}
                </v-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="GDP" vid="gdp" rules="required|min_value:0">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="gdp"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('globalTrans.gdp')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="number"
                  v-model="form.gdp"
                  id="gdp"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Currency" vid="currency_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="currency_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.currency')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  disabled
                  v-model="form.currency"
                  :options="currencyList"
                  id="currency_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="PCI" vid="pci" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="pci"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.pci')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="number"
                  v-model="form.pci"
                  id="pci"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Agricultural Products" vid="agricultural_products">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="agricultural_products"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('tradeTariffApp.agricultural_product')}}
                </template>
                <v-select
                  id="agricultural_products"
                  v-model="form.agricultural_products"
                  multiple
                  :reduce="op => op.value"
                  :options="agriculturalProductList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Officer Name" vid="industrial_products">
              <b-form-group
                label-for="industrial_products"
                label-cols-sm="4"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.industrial_product') }}
              </template>
              <v-select
                  id="agricultural_products"
                  v-model="form.industrial_products"
                  multiple
                  :reduce="op => op.value"
                  :options="industryProductList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
                <b>{{ $t('tradeTariffApp.export_status')}} </b><span class="text-danger">*</span>
            <ValidationProvider name="Export Status" vid="export_status">
              <b-form-radio-group
                  id="export_status"
                  v-model="form.export_status"
                  :options="exportStatusList"
                  name="export_status"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-radio-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <ValidationProvider name="Attachment" vid="attachment_main" :rules="attachmentx1 ? '' : 'required'">
              <b-form-group slot-scope="{ valid, errors }" label-for="attachment_main" >
                <template v-slot:label>
                    {{$t('globalTrans.attachment')}}<span class="text-danger">* {{ $t('externalTradeTraiff.attachment_image') }}</span>
                </template>
                <div class="d-flex">
                  <b-form-file accept=".pdf,.doc,.xls,.xlsx" id="attachment_main" v-model="form.file"
                    @change="onFileChange" :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="attachmentx1 ? attachmentx1 : $t('globalTrans.attachment_placeholder')"
                    :drop-placeholder="$t('externalTradeTraiff.attachment_drop_placeholder')"></b-form-file>
                </div>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-overlay>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { countryInformationManagementStore, tariffPortsLstApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      seaPortsList: [],
      landPortsList: [],
      airPortsList: [],
      unitLoading: false,
      attachmentx1: '',
      form: {
        country_id: 0,
        capital_id: 0,
        area: '',
        population: '',
        sea_ports: [],
        air_ports: [],
        land_ports: [],
        gdp: '',
        currency: 0,
        pci: '',
        agricultural_products: [],
        industrial_products: [],
        attachment_main: '',
        file: [],
        export_status: 1
      },
      exportStatusList: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Active' : 'সক্রিয়' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Inactive' : 'নিষ্ক্রিয়' }
      ],
      unitLoad: false,
      capitalInfoList: [],
      currencyList: [],
      officeList: [],
      designationList: []
    }
  },
  created () {
    if (this.id) {
      this.unitLoad = true
      const tmp = this.getEditInfo()
      this.form = tmp
      this.attachmentx1 = this.form.attachment_main.split('/')[3]
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    agriculturalProductList: function () {
      return this.$store.state.TradeTariffService.commonObj.productList.filter(item => item.status === 1 && item.product_type_id === 1)
    },
    industryProductList: function () {
      return this.$store.state.TradeTariffService.commonObj.productList.filter(item => item.status === 1 && item.product_type_id === 2)
    },
    passwordRules: function () {
      return this.id ? '' : 'required|min:6'
    },
    passwordConfirmRules: function () {
      return this.id ? '' : 'required|min:6'
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeSeaPortsList(newVal)
      }
    },
    'form.country_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.currencyList = this.getCurrencyList(newVal)
        this.capitalInfoList = this.getCapitalInfoList(newVal)
        this.getPorts()
      }
    },
    'form.capital_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'form.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      }
    }
  },
  methods: {
    onFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.form.attachment_main = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.form.attachment_main = ''
        }
    },
    getPorts () {
      this.getPortsList(this.form.country_id)
    },
    getCurrencyList (countryId) {
        const p = this.$store.state.TradeTariffService.commonObj.currencyList.filter(item => item.status === 1 && item.country_name_id === parseInt(countryId))
        if (p.length > 0) {
          this.form.currency = p.find(value => value.country_name_id === parseInt(countryId)).value
        } else {
          this.form.currency = 0
        }
        return p
    },
    getCapitalInfoList (countryId) {
      const p = this.$store.state.TradeTariffService.commonObj.capitalInfoList.filter(offType => offType.country_name_id === parseInt(countryId))
      if (p.length > 0) {
        this.form.capital_id = p.find(offType => offType.country_name_id === parseInt(countryId)).value
      } else {
        this.form.capital_id = 0
      }
      return p
    },
    getOfficeList (officeTypeId) {
      return this.$store.state.CommonService.commonObj.officeList.filter(office => office.capital_id === officeTypeId)
    },
    getDesignationList (officeId) {
      return this.$store.state.CommonService.commonObj.assignDesignationList.filter(desgn => desgn.office_id === officeId && desgn.capital_id === this.form.capital_id && desgn.country_id === this.form.country_id)
    },
    getPortsList (countryId) {
      this.unitLoading = true
        RestApi.getData(tradeTariffServiceBaseUrl, `${tariffPortsLstApi}/${countryId}`).then(response => {
        const ports = response.data.filter(obj => obj.status === 1)
        const landPorts = ports.filter(obj => obj.port_type_id === 1).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.id,
            text: this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn,
            text_en: obj.port_name_en,
            text_bn: obj.port_name_bn
          })
        })
        const seaPorts = ports.filter(obj => obj.port_type_id === 2).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.id,
            text: this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn,
            text_en: obj.port_name_en,
            text_bn: obj.port_name_bn
          })
        })
        const airPorts = ports.filter(obj => obj.port_type_id === 3).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.id,
            text: this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn,
            text_en: obj.port_name_en,
            text_bn: obj.port_name_bn
          })
        })
        this.seaPortsList = seaPorts
        this.landPortsList = landPorts
        this.airPortsList = airPorts
        this.unitLoading = false
        this.unitLoad = false
      })
    },
    handlePhoto (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.form.photo = event.target.result
        this.form.update_photo = 1
      }
      reader.readAsDataURL(selectedPhoto)
    },
    localizeSeaPortsList (locale) {
      const tmp = this.seaPortsList.map(item => {
        return Object.assign({}, item, {
          text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn
        })
      })
      this.seaPortsList = tmp
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = this.form
      this.form.id = this.id ? this.id : 0
      result = await RestApi.postData(tradeTariffServiceBaseUrl, countryInformationManagementStore, formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message,
          color: '#FF0000'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
