<template>
  <b-container fluid>
  <b-overlay :show="unitLoad">
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <b-col md="12">
                        <table style="line-height: 25px" class="table table-sm table-borderless">
                            <tr>
                                <th>{{ $t('globalTrans.country')}}</th><td>: {{ getCountryName(items.country_id) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffConfig.capital_name')}}</th><td>: {{ getCapitalName(items.capital_id) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('globalTrans.population')}}</th><td>: {{ $n(items.population) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('globalTrans.gdp')}}</th><td>: {{ $n(items.gdp) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('globalTrans.area')}}</th><td>: {{ $n(items.area) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('globalTrans.currency')}}</th><td>: {{ getCurrencyName(items.currency) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.sea_port')}}</th><td>: <span v-for="(data,index) in items.sea_ports" :key="index"> {{ getSeaPortName(data) }}, </span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.air_port')}}</th><td>: <span v-for="(data,index) in items.air_ports" :key="index"> {{ getAirPortName(data) }}, </span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.land_port') }}</th><td>: <span v-for="(data,index) in items.land_ports" :key="index"> {{ getLandPortName(data) }}, </span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.pci')}}</th><td>: {{ $n(items.pci) }} </td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.agricultural_product')}}</th><td>: <span v-for="(data,index) in items.agricultural_products" :key="index"> {{ getProductName(data) }}, </span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.industrial_product') }}</th><td>: <span v-for="(data,index) in items.industrial_products" :key="index"> {{ getProductName(data) }}, </span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.export_status')}}</th><td>: <span v-if="items.export_status === 1">{{ $t('globalTrans.active')}}</span> <span v-if="items.export_status === 2">{{ $t('globalTrans.inactive')}}</span></td>
                            </tr>
                            <tr>
                                <th>{{ $t('tradeTariffApp.country_map')}}</th><td>:
                                    <span v-if="items.attachment_main !== null">
                                        <a target="_blank" :href="tradeTariffServiceBaseUrl+'download-attachment?file='+ 'storage' +items.attachment_main" title="Download">
                                        <img class="img-thumbnail w-25" style="height: auto; width: 100px" :src="tradeTariffServiceBaseUrl + 'storage' + items.attachment_main" alt="Profile Image">
                                        </a>
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
    </b-row>
  </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { tariffPortsLstApi } from '../../api/routes'
export default {
    props: ['items'],
    country: null,
    components: {
    },
    created () {
        this.country = this.$store.state.TradeTariffService.commonObj.currencyList.find(item => item.value === 1)
        this.getPorts()
    },
    mounted () {
    },
    data () {
        return {
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            unitLoad: true,
            ports: [],
            seaPortsList: [],
            landPortsList: [],
            airPortsList: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getCountryName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCapitalName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.capitalInfoList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCurrencyName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.currencyList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getSeaPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getAirPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getLandPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getProductName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.productList.find(item => item.status === 1 && item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getPorts () {
            this.getPortsList(this.items.country_id)
        },
        getPortsList (countryId) {
            RestApi.getData(tradeTariffServiceBaseUrl, `${tariffPortsLstApi}/${countryId}`).then(response => {
                this.ports = response.data.filter(obj => obj.status === 1)
                this.unitLoad = false
            })
        }
    }
}

</script>
